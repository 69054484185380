<template>
    <el-row style="margin-top: 10px;" class="sticky-div">
        <el-col :span="24">
            <div style="border: 1 solid black;margin: 10px;">
                <div style="display: flex;justify-items: center;">
                    <div style="display: flex; justify-items: center;">
                        <span style="margin:5px;display: flex;justify-content: center;" @click="edit">
                            <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M6.41421 15.89L16.5563 5.74785L15.1421 4.33363L5 14.4758V15.89H6.41421ZM7.24264 17.89H3V13.6473L14.435 2.21231C14.8256 1.82179 15.4587 1.82179 15.8492 2.21231L18.6777 5.04074C19.0682 5.43126 19.0682 6.06443 18.6777 6.45495L7.24264 17.89ZM3 19.89H21V21.89H3V19.89Z">
                                </path>
                            </svg>
                        </span>
                        <span style="display: flex;justify-content: center;align-items: center;">|</span>
                        <span style="margin:5px;display: flex;justify-content: center;" @click="deleteById">
                            <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z">
                                </path>
                            </svg>
                        </span>
                        <span style="display: flex;justify-content: center;align-items: center;">|</span>
                        <span style="margin:5px;display: flex;justify-content: center;" @click="copyHref">
                            <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M13.0607 8.11097L14.4749 9.52518C17.2086 12.2589 17.2086 16.691 14.4749 19.4247L14.1214 19.7782C11.3877 22.5119 6.95555 22.5119 4.22188 19.7782C1.48821 17.0446 1.48821 12.6124 4.22188 9.87874L5.6361 11.293C3.68348 13.2456 3.68348 16.4114 5.6361 18.364C7.58872 20.3166 10.7545 20.3166 12.7072 18.364L13.0607 18.0105C15.0133 16.0578 15.0133 12.892 13.0607 10.9394L11.6465 9.52518L13.0607 8.11097ZM19.7782 14.1214L18.364 12.7072C20.3166 10.7545 20.3166 7.58872 18.364 5.6361C16.4114 3.68348 13.2456 3.68348 11.293 5.6361L10.9394 5.98965C8.98678 7.94227 8.98678 11.1081 10.9394 13.0607L12.3536 14.4749L10.9394 15.8891L9.52518 14.4749C6.79151 11.7413 6.79151 7.30911 9.52518 4.57544L9.87874 4.22188C12.6124 1.48821 17.0446 1.48821 19.7782 4.22188C22.5119 6.95555 22.5119 11.3877 19.7782 14.1214Z">
                                </path>
                            </svg>
                        </span>
                        <span style="display: flex;justify-content: center;align-items: center;">|</span>
                        <span style="margin:5px;display: flex;justify-content: center;" @click="shareHref">
                            <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M13 14H11C7.54202 14 4.53953 15.9502 3.03239 18.8107C3.01093 18.5433 3 18.2729 3 18C3 12.4772 7.47715 8 13 8V2.5L23.5 11L13 19.5V14ZM11 12H15V15.3078L20.3214 11L15 6.69224V10H13C10.5795 10 8.41011 11.0749 6.94312 12.7735C8.20873 12.2714 9.58041 12 11 12Z">
                                </path>
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </el-col>
    </el-row>
    <hr />
    <el-row style="margin-top: 10px;">
        <el-col :span="24">
            <div class="dou_note_title">{{ info.name }}</div>
            <div class="dou_note_author">dousoul | 最后更新:2024/09/09 16:33:20</div>
            <div v-for="paragraph in sortedParagraphs" :key="paragraph.id" class="paragraph-container">
                <!-- 代码段：显示行号、语言名和复制按钮 -->
                <pre v-if="isCodeParagraph(paragraph.type)" class="line-numbers"><code ref="codeRef" :class="[getCodeClass(paragraph.type)]">{{ paragraph.content }}</code>
                </pre>
                <!-- 普通段落 -->
                <p v-else-if="paragraph.type == '一级段落'" class="dou_note_h1">{{ paragraph.content }}</p>
                <p v-else-if="paragraph.type == '二级段落'" class="dou_note_h2">{{ paragraph.content }}</p>
                <p v-else-if="paragraph.type == '三级段落'" class="dou_note_h3">{{ paragraph.content }}</p>
                <p v-else v-html="paragraph.content.split('\n').join('<br/>')"></p>
            </div>
        </el-col>
    </el-row>
</template>
<script setup>
import { ref, onMounted, defineProps, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import api from '../../../api/api.js';
import { ElMessage } from 'element-plus';
import { ElMessageBox } from 'element-plus';

const info = ref({});
const paragraphs = ref([]);
const sortedParagraphs = ref([]);
const codeRef = ref(null);
const route = useRouter();

// 支持的语言类型与对应的 PrismJS class
const supportedLanguages = {
    'C#': 'language-csharp',
    'JavaScript': 'language-javascript',
    'Java': 'language-java',
    'HTML': 'language-markup',
    "XML": "language-xml",
    "SQL": "language-sql",
    // 这里可以继续添加其他支持的语言
};

const props = defineProps(["id"]);
onMounted(async () => {
    var infoResult = await api.GetNoteById(props.id);
    info.value = infoResult;
    fetchParagraphs();
})

// 判断是否为代码段
const isCodeParagraph = (type) => !!supportedLanguages[type];

// 根据类型返回对应的代码高亮 class
const getCodeClass = (type) => supportedLanguages[type] || '';

const sortParagraphs = (paragraphs) => {
    // 1. 寻找起始段落（prevId 为 null）
    const startParagraph = paragraphs.find(p => p.prevId === null);
    if (!startParagraph) {
        console.error("没有找到起始段落！");
        return [];
    }

    // 2. 按顺序连接段落
    const sorted = [];
    let currentParagraph = startParagraph;
    while (currentParagraph) {
        sorted.push(currentParagraph);
        currentParagraph = paragraphs.find(p => p.id === currentParagraph.nextId);
    }

    return sorted;
};

const fetchParagraphs = async () => {
    paragraphs.value = info.value.paragraphs;
    // 调用排序函数
    sortedParagraphs.value = sortParagraphs(paragraphs.value);
    // 数据更新后使用 PrismJS 渲染代码高亮
    await nextTick(); // 确保 DOM 更新完成
    const Prism = await import('prismjs')
    await import('prismjs/themes/prism-tomorrow.css')
    await import('prismjs/components/prism-javascript.min.js')
    await import('prismjs/components/prism-java.min.js')
    await import('prismjs/components/prism-csharp.min.js')
    await import('prismjs/components/prism-markup.min.js')
    await import('prismjs/plugins/line-numbers/prism-line-numbers.js')
    await import('prismjs/plugins/line-numbers/prism-line-numbers.css')
    await import('prismjs/plugins/toolbar/prism-toolbar.js')
    await import('prismjs/plugins/toolbar/prism-toolbar.css')
    await import('prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.js')

    Prism.highlightAll();
};

const edit = () => {
    route.push({ path: "/NoteSave/" + props.id });
}
const deleteById = async () => {
    var message = "删除笔记后不可撤销，是否删除?";
    ElMessageBox.confirm(message, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(async () => {
        try {
            await api.DeleteNoteById(props.id);
            ElMessage({
                message: '删除成功！',
                type: 'success',
            })
            route.back();
        }
        catch (error) {
            ElMessage.error("删除失败，请重试！" + error.response.data);
        }
    }).catch(() => {
    });
}
const copyHref = async () => {
    const text = window.location.origin + "/#/s/note/" + props.id;
    try {
        // 首先检查浏览器是否支持 Clipboard API
        if (navigator.clipboard && window.isSecureContext) {
            // 使用 Clipboard API 复制文本
            await navigator.clipboard.writeText(text);
            ElMessage({
                message: '复制成功！',
                type: 'success',
            })
        } else {
            // 处理不支持 Clipboard API 的情况，使用选中和 document.execCommand 来复制
            const textarea = document.createElement('textarea');
            textarea.value = text;
            textarea.style.position = 'fixed'; // 防止移动页面
            textarea.style.opacity = '0'; // 隐藏 textarea
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();

            const success = document.execCommand('copy');
            document.body.removeChild(textarea);

            if (success) {
                ElMessage({
                    message: '复制成功！',
                    type: 'success',
                })
            } else {
                ElMessage.error("复制失败，请重试！");
            }
        }
    } catch (err) {
        ElMessage.error("复制失败，请重试！");
    }
}
const shareHref = async () => {
    const text = window.location.origin + "/#/s/note/" + props.id;
    if (navigator.share) {
        try {
            await navigator.share({
                title: '我的 Vue 3 项目',
                text: '这是一个分享示例',
                url: text,
            });
            console.log('分享成功');
        } catch (error) {
            console.error('分享失败:', error);
        }
    } else {
        ElMessage.error("Web Share API 不支持");
    }
}
</script>
<style scoped>
.sticky-div {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    /* 确保它在其他元素之上 */
}

/* 移除选中黄框 */
pre,
code {
    outline: none;
}

.dou_note_h1 {
    font-size: 22px;
    font-weight: bold;
}

.dou_note_h2 {
    font-size: 20px;
    font-weight: bold;
}

.dou_note_h3 {
    font-size: 18px;
    font-weight: bold;
}

.dou_note_title {
    font-size: 26px;
    font-weight: bold;
}

.dou_note_author {
    color: gray;
    font-size: 14px;
}

p {
    margin-block-start: 0.5em !important;
    margin-block-end: 0.5em !important;
}
</style>