import axios from 'axios';
import store from '../store'; // 导入 Vuex store
import {
    ElLoading
} from 'element-plus';

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const TOKEN_STORAGE_KEY = 'TOKEN_KEY';
const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});
// 定义一个包含 refresh 方法的对象
const apiWithRefresh = {
    // 刷新 Token 接口
    refresh: async (refreshToken) => {
        const response = await api.post('/api/Auth/refreshToken', refreshToken);
        return response.data;
    }
};

// 请求拦截器
api.interceptors.request.use(
    (config) => {
        const token = store.getters['user/getToken']; // 从 Vuex 中获取 Token
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器
api.interceptors.response.use(
    async (response) => response,
    async (error) => {
        // 如果响应返回 401，可能是 Token 过期，尝试刷新 Token
        if (error.response && error.response.status === 401) {
            try {
                // 尝试刷新 Token
                const refreshToken = store.getters['user/getToken'];
                if (refreshToken) {
                    const newToken = await apiWithRefresh.refresh(refreshToken);

                    // 保存新 Token 到 Vuex 和本地存储
                    store.commit('user/SET_TOKEN', newToken.token);
                    localStorage.setItem(TOKEN_STORAGE_KEY, newToken.token);

                    // 重新发起原始请求
                    const config = error.config;
                    config.headers.Authorization = `Bearer ${newToken.token}`;
                    return api(config);
                } else {
                    store.dispatch('user/logout');
                }
            } catch (refreshError) {
                // 刷新 Token 失败，执行注销操作
                //store.dispatch('user/logout');
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);
const request = async (config) => {
    const loadingInstance = ElLoading.service({
        fullscreen: true,
        text: '加载中...',
    });
    try {
        const response = await api(config);
        return response.data;
    } finally {
        loadingInstance.close();
    }
};

export default {
    request,
    //通用列表获取
    GetGenericDataList: async (entityName, pageNumber, pageSize, orderBy, searchText) => {
        var requestObj = {
            method: 'get',
            url: '/api/Generic/query',
            params: {
                entityName: entityName,
                pageNumber: pageNumber,
                pageSize: pageSize
            }
        };
        if (orderBy != null) {
            requestObj.params.orderBy = orderBy;
        }
        if (searchText != null) {
            requestObj.params.searchText = searchText;
        }
        return await request(requestObj);
    },
    //通用实体保存
    SaveGenericData: async (entityName, data) => {
        return await request({
            method: 'post',
            url: '/api/Generic/saveData',
            data: {
                entityName: entityName,
                entityData: JSON.stringify(data),
            },
        });
    },
    //通用实体id获取
    GetGenericDataById: async (entityName, id) => {
        return await request({
            method: 'get',
            url: '/api/Generic/getData',
            params: {
                entityName: entityName,
                id: id
            }
        });
    },
    //通用实体id删除
    DeleteGenericDataById: async (entityName, id) => {
        return await request({
            method: 'delete',
            url: '/api/Generic/deleteData',
            params: {
                entityName: entityName,
                id: id
            }
        });
    },
    //通用枚举获取
    GetGenericEnum: async (data) => {
        return await request({
            method: 'post',
            url: '/api/Generic/getEnumData',
            data: data,
        });
    },
    //获取七天内日程
    GetTodoList: async (type) => {
        return await request({
            method: 'get',
            url: '/api/Diary/GetIndexData',
            params: {
                type: type
            }
        });
    },
    //获取待做
    GetScheduleList: async () => {
        return await request({
            method: 'get',
            url: '/api/Schedules',
        });
    },
    //@@账单模块@@
    BillSave: async (data) => {
        return await request({
            method: 'post',
            url: '/api/Bill/Save',
            data: data
        });
    },
    BillDelete: async (id) => {
        return await request({
            method: 'delete',
            url: '/api/Bill/Delete',
            params: {
                id: id
            }
        });
    },
    GetBillTypePie: async (type, start, end, searchText, isTopLevel) => {
        return await request({
            method: 'get',
            url: '/api/Bill/GetBillTypePie',//获取日报表分类饼图
            params: {
                type: type,
                startDate: start,
                endDate: end,
                searchText: searchText,
                topLevel: isTopLevel
            }
        });
    },
    CardAdjustSave: async (data) => {
        return await request({
            method: 'post',
            url: '/api/Bill/SaveAdjust',
            data: data
        });
    },
    CardTransterSave: async (data) => {
        return await request({
            method: 'post',
            url: '/api/Bill/Transter',
            params: data
        });
    },
    CardAdjustDelete: async (id) => {
        return await request({
            method: 'delete',
            url: '/api/Bill/DeleteAdjust',
            params: {
                id: id
            }
        });
    },
    GetCardList: async () => {
        return await request({
            method: 'get',
            url: '/api/Bill/CardList',
        });
    },
    GetBillExistInfo: async () => {
        return await request({
            method: 'get',
            url: '/api/Bill/GetBillExistInfo',//获取账单类型
        });
    },
    GetBillHomeInfo: async () => {
        return await request({
            method: 'get',
            url: '/api/Bill/GetBillHomeInfo',//获取账单首页展示信息
        });
    },
    GetBillAllInfo: async () => {
        return await request({
            method: 'get',
            url: '/api/Bill/GetBillAllInfo',//获取BillAll页的展示信息
        });
    },
    GetBorrowExistInfo: async () => {
        return await request({
            method: 'get',
            url: '/api/Bill/GetBorrowExistInfo',//获取已存在的借贷基本信息
        });
    },
    BorrowSave: async (data) => {
        return await request({
            method: 'post',
            url: '/api/Bill/SaveBorrow',
            data: data
        });
    },
    GetRemainAmount: async (entityName, id) => {
        return await request({
            method: 'get',
            url: '/api/Bill/GetRemainAmount',//获取卡片余额等综合信息
            params: {
                type: entityName,
                cardId: id
            }
        });
    },
    //【账户开始】
    SaveSafeKeyHold: async (data) => {
        return await request({
            method: 'post',
            url: '/api/Account/SaveSafeKeyHold',//保存密钥
            data: data
        });
    },
    GetAccountExt: async (id) => {
        return await request({
            method: 'get',
            url: '/api/Account/GetAccountExt',//获取Account额外信息
            params: {
                id: id
            }
        });
    },
    //【账户结束】
    //【Diary开始】
    GetDaily: async (date) => {
        return await request({
            method: 'get',
            url: '/api/Diary/GetDaily',//获取Plan额外信息
            params: {
                date: date
            }
        });
    },
    SaveDaily: async (planData, memo) => {
        return await request({
            method: 'post',
            url: '/api/Diary/SaveDaily',//获取Plan额外信息
            data: {
                PlanData: JSON.stringify(planData),
                Memo: memo
            }
        });
    },
    GetPlanExt: async (id) => {
        return await request({
            method: 'get',
            url: '/api/Diary/GetPlanExt',//获取Plan额外信息
            params: {
                id: id
            }
        });
    },
    DeletePlan: async (id) => {
        return await request({
            method: 'delete',
            url: '/api/Diary/DeletePlan',
            params: {
                id: id
            }
        });
    },
    //通用列表获取[自定义]
    GetPlanList: async (pageNumber, pageSize, searchText) => {
        var requestObj = {
            method: 'get',
            url: '/api/Diary/GetPlanList',
            params: {
                pageNumber: pageNumber,
                pageSize: pageSize
            }
        };
        if (searchText != null) {
            requestObj.params.searchText = searchText;
        }
        return await request(requestObj);
    },
    //保存计划[自定义]
    SavePlan: async (entityName, data) => {
        return await request({
            method: 'post',
            url: '/api/Diary/SavePlan',
            data: {
                entityName: entityName,
                entityData: JSON.stringify(data),
            },
        });
    },
    //保存目标[自定义]
    SaveGoal: async (entityName, data) => {
        return await request({
            method: 'post',
            url: '/api/Diary/SaveGoal',
            data: {
                entityName: entityName,
                entityData: JSON.stringify(data),
            },
        });
    },
    //获取目标的Ext数据
    GetGoalExt: async (id) => {
        return await request({
            method: 'get',
            url: '/api/Diary/GetGoalExt',//获取Plan额外信息
            params: {
                id: id
            }
        });
    },
    ////删除目标[自定义]
    DeleteGoal: async (id) => {
        return await request({
            method: 'delete',
            url: '/api/Diary/DeleteGoal',
            params: {
                id: id
            }
        });
    },
    //保存目标执行[自定义]
    SaveGoalExec: async (data) => {
        return await request({
            method: 'post',
            url: '/api/Diary/SaveGoalExec',
            data: data
        });
    },
    //获取目标执行Ext
    GetGoalExecExt: async (id) => {
        return await request({
            method: 'get',
            url: '/api/Diary/GetGoalExecExt',//获取Plan额外信息
            params: {
                id: id
            }
        });
    },
    //保存目标执行状态[自定义]
    UpdateGoalExecStatus: async (data) => {
        return await request({
            method: 'post',
            url: '/api/Diary/UpdateGoalExecStatus',
            params: {
                id: data.id,
                status: data.status,
                memo: data.memo
            }
        });
    },
    //获取首页数据
    GetHome: async () => {
        return await request({
            method: 'get',
            url: '/api/Diary/GetHome'
        });
    },
    //获取日历数据
    GetCalendar: async (date) => {
        return await request({
            method: 'get',
            url: '/api/Diary/GetCalendar',
            params: {
                date: date
            }
        });
    },
    //【Diary结束】
    //【Note】
    //获取Note的List数据
    GetNoteList: async (pageNo, pageSize, searchText, type) => {
        var params = {
            pageNo: pageNo,
            pageSize: pageSize
        };
        if (searchText != null && searchText.trim() != "") {
            params.searchText = searchText;
        }
        if (type != null && type.trim() != "") {
            params.type = type;
        }
        return await request({
            method: 'get',
            url: '/api/Note/GetList',//获取Plan额外信息
            params: params
        });
    },
    //获取已存在的笔记信息
    GetNoteExistInfo: async () => {
        return await request({
            method: 'get',
            url: '/api/Note/GetExistInfo',
        });
    },
    //保存笔记信息
    SaveNote: async (data) => {
        return await request({
            method: 'post',
            url: '/api/Note/SaveNote',
            data: data
        });
    },
    //通过id获取笔记信息
    GetNoteById: async (id) => {
        return await request({
            method: 'get',
            url: '/api/Note/GetNoteById',//获取笔记详情
            params: {
                id: id
            }
        });
    },
    //通用id删除笔记信息
    DeleteNoteById: async (id) => {
        return await request({
            method: 'delete',
            url: '/api/Note/DeleteNoteById',
            params: {
                id: id
            }
        });
    },
    //【Note结束】
    //【百宝箱开始】
    GetWheelConfig: async (pid, id) => {
        return await request({
            method: 'get',
            url: '/api/Fun/GetWheelConfig',//获取抽奖[转盘]配置
            params: {
                raffleId: pid,
                hrefId: id
            }
        });
    },
    DrawLottery: async (raffleId, hrefId, isDebug) => {
        return await request({
            method: 'post',
            url: '/api/Fun/DrawLottery',//获取Account额外信息
            params: {
                raffleId: raffleId,
                hrefId: hrefId,
                isDebug: isDebug
            }
        });
    },
    GetRaffleInfo: async (id) => {
        return await request({
            method: 'get',
            url: '/api/Fun/GetRaffleInfo',//获取抽奖Info页面初始化信息
            params: {
                raffleId: id
            }
        });
    },
    DeleteRaffleResult: async (id) => {
        return await request({
            method: 'delete',
            url: '/api/Fun/DeleteRaffleResult',
            params: {
                id: id
            }
        });
    },
    //【百宝箱结束】
    //【Share】
    //通过id获取笔记信息
    GetShareNoteById: async (id) => {
        return await request({
            method: 'get',
            url: '/api/Share/GetNoteById',//获取笔记详情
            params: {
                id: id
            }
        });
    },
    //【Share结束】
    //【System】
    //保存功能介绍[自定义]
    SavePageFeature: async (data) => {
        return await request({
            method: 'post',
            url: '/api/System/SavePageFeature',
            data: data
        });
    },
    //功能介绍查询
    GetPageFeature: async (path, name) => {
        return await request({
            method: 'get',
            url: '/api/Generic/GetPageFeature',
            params: {
                path: path,
                name: name
            }
        });
    },
    //【System结束】
    //@@以下为基础请求@@
    //获取表结构
    GetDB: async () => {
        return await request({
            method: 'get',
            url: '/api/Generic/getdb',
        });
    },
};