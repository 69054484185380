import {
  createApp
} from 'vue';
import {
  createRouter,
  createWebHistory
} from 'vue-router';
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import { ElIcon } from '@element-plus/icons-vue';
import App from './App.vue';
import store from './store'; // 引入 Vuex store
import HomePage from './components/Content/HomePage.vue';
import UpdateLog from './components/Content/UpdateLog.vue';

import { douTable } from './config.js';//引入元douTable
import api from './api/api.js';//调用接口

//布局页
import LayoutUser from './components/Layout/LayoutUser.vue';
import LayoutShare from './components/Layout/LayoutShare.vue';
//通用列表
import GenericList from './components/Content/Generic/GenericList.vue';
import GenericSearch from './components/Content/Generic/GenericSearch.vue';
import GenericPageSave from './components/Content/Generic/GenericPageSave.vue';
import GenericPageInfo from './components/Content/Generic/GenericPageInfo.vue';
import GenericPageList from './components/Content/Generic/GenericPageList.vue';

import UserManual from './components/Content/UserManual.vue';
import UserSetting from './components/Content/UserSetting.vue';
import UserLogin from './components/Content/UserLogin.vue';

//业务组件
import Bill from './components/Business/Bill/BillHome.vue';//账单主页
import BillSave from './components/Business/Bill/BillSave.vue';//账单保存页自定义
import BillInfo from './components/Business/Bill/BillInfo.vue';//账单详情
import BillAll from './components/Business/Bill/BillAll.vue';//总结余
import BillBorrowSave from './components/Business/Bill/BillBorrowSave.vue';//借贷保存页自定义
import BillDayReport from './components/Business/Bill/BillDayReport.vue';//账单日分析
import Test from './components/Business/Test/TestHome.vue';
//笔记
import NoteList from './components/Business/Note/NoteList.vue';//笔记列表
import NoteSave from './components/Business/Note/NoteSave.vue';//笔记保存
import NoteInfo from './components/Business/Note/NoteInfo.vue';//笔记保存
import NoteShare from './components/Business/Note/NoteShare.vue';//笔记保存
//账户
import AccountInfo from './components/Business/Account/AccountInfo.vue';//账户详情
//日常
import DiarySave from './components/Business/Diary/DiarySave.vue';//日常保存
//百宝箱
import FunWheel from './components/Business/Fun/FunWheel.vue'; //转盘测试
import WheelShare from './components/Business/Fun/WheelShare.vue'; //转盘分享

//全局组件
import GlobalBreadcrumb from './components/Common/Global/GlobalBreadcrumb.vue';
const routes = [{
  path: '/',
  component: LayoutUser,
  children: [
    {
      path: '',
      component: HomePage,
      meta: { requireAuth: true, title: '首页' } // 这里设置为需要身份验证
    },
    {
      path: 'list/:entityName',//通用列表
      name: 'List',
      component: GenericList,
      props: true,
      meta: { requireAuth: true }
    },
    {
      path: 'search/:entityName',//通用列表搜索
      name: 'search',
      component: GenericSearch,
      props: true,
      meta: { requireAuth: true }
    },
    {
      path: 'updateLog',
      component: UpdateLog,
      meta: { requireAuth: true }
    },
    {
      path: 'user-manual',
      component: UserManual,
      meta: { requireAuth: true }
    },
    {
      path: 'user-setting',
      component: UserSetting,
      meta: { requireAuth: true }
    },
    {
      path: 'login', //用户登录
      component: UserLogin
    },
    {
      //账单模块
      path: 'Bill',//通用列表
      name: "Bill",
      component: Bill,
      meta: { requireAuth: true }
    },
    {
      path: 'Bill/Save/:id',//保存页
      name: "BillSave",
      component: BillSave,
      meta: { requireAuth: true }
    },
    {
      path: 'Bill/Info',//详情页
      name: "BillInfo",
      component: BillInfo,
      meta: { requireAuth: true }
    },
    {
      path: 'Bill/All',//详情页
      name: "BillAll",
      component: BillAll,
      meta: { requireAuth: true }
    },
    {
      path: 'Bill/DayReport',
      name: "BillDayReport",
      component: BillDayReport,
      meta: { requireAuth: true }
    },
    {
      path: 'Bill/BorrowSave/:id',//保存页
      name: "BorrowSave",
      component: BillBorrowSave,
      meta: { requireAuth: true }
    },
    {
      path: 'Account/Info',//【账户开始】
      name: "AccountInfo",
      component: AccountInfo,
      meta: { requireAuth: true }
    },
    {
      path: 'Diary/Save',//【账户开始】
      name: "DiarySave",
      component: DiarySave,
      meta: { requireAuth: true }
    },
    {
      path: 'ps/:entityName/:id',//通用保存页
      component: GenericPageSave,
      name: 'PageSave',
      meta: { requireAuth: true }
    },
    {
      path: 'pi/:entityName/:id',//通用保存页
      props: true,
      component: GenericPageInfo,
      name: 'PageInfo',
      meta: { requireAuth: true }
    },
    {
      path: 'pl/:entityName',//通用列表页
      props: true,
      component: GenericPageList,
      name: 'PageList',
      meta: { requireAuth: true }
    },
    {
      //功能测试
      path: 'Test',//测试
      component: Test,
      meta: { requireAuth: true, roles: ["Admin"] }
    },
    {
      //笔记模块
      path: 'NoteList',//通用列表
      name: "NoteList",
      component: NoteList,
      meta: { requireAuth: true }
    },
    {
      path: 'NoteSave/:id',//Note保存
      name: "NoteSave",
      props:true,
      component: NoteSave,
      meta: { requireAuth: true }
    },
    {
      path: 'NoteInfo/:id',//Note详情
      name: "NoteInfo",
      props:true,
      component: NoteInfo,
      meta: { requireAuth: true }
    },
    {
      //百宝箱开始
      path: 'Fun/Wheel',
      name: "FunWheel",
      component: FunWheel,
      meta: { requireAuth: true }
    }
  ]
}, {
  path: '/admin/',
  component: LayoutUser,
  meta: { requireAuth: true, roles: ['Admin'] },
  children: [
    {
      path: 'ps/:entityName/:id',//通用保存页
      component: GenericPageSave,
      name: 'AdminPageSave'
    },
    {
      path: 'pi/:entityName/:id',//通用保存页
      props: true,
      component: GenericPageInfo,
      name: 'AdminPageInfo'
    },
    {
      path: 'pl/:entityName',//通用列表页
      props: true,
      component: GenericPageList,
      name: 'AdminPageList'
    }
  ]
},{
  path: '/s/',
  component: LayoutShare,
  children: [
    {
      path: 'note/:id',//通用保存页
      component: NoteShare,
      name: 'NoteShare',
      props:true
    },
    {
      path: 'wheel/:pid/:id',//通用保存页
      component: WheelShare,
      name: 'WheelShare',
      props:true
    }
  ]
}]
const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHistory(),
  routes, // `routes: routes` 的缩写
})

// 在应用初始化时尝试从 localStorage 恢复登录状态
store.dispatch('user/restoreLoginState', router);

// 添加路由导航守卫
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["user/isAuthenticated"];
  const userRole = store.getters["user/getUserRole"];

  // 如果需要鉴权
  if (to.meta.requireAuth) {
    if (!isAuthenticated) {
      // 未登录，跳转到登录页
      next('/login');
    } else {
      // 已登录，检查是否需要角色
      if (to.meta.roles) {
        // 检查用户角色是否符合
        if (to.meta.roles.includes(userRole)) {
          next();
        } else {
          // 角色不匹配，跳转到禁止页
          next('/denied');
        }
      } else {
        // 没有roles限制，直接继续
        next();
      }
    }
  } else {
    // 不需要鉴权，正常访问
    next();
  }
});

//初始表结构
var serverDB = await api.GetDB();
Object.keys(serverDB).forEach(key => {
  douTable[key] = serverDB[key];
});
store.commit('user/SET_DOU_TABLE', douTable);

const app = createApp(App);
app.use(store); // 使用 Vuex
app.use(router);
app.use(ElementPlus, {
  locale: zhCn,
});
app.component("ElIcon", ElIcon);
app.component("GlobalBreadcrumb", GlobalBreadcrumb);
app.mount('#app');