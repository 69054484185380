<template>
    <button v-show="false" @click="saveChildPlan"></button>
    <button v-show="false" @click="showRowInfo"></button>
    <el-row>
        <el-col :span="24">
            <GlobalBreadcrumb :name="'日志'"></GlobalBreadcrumb>
        </el-col>
    </el-row>
    <el-row style="margin-top:10px">
        <el-date-picker v-model="choosedDate" @change="updateDate" type="date" placeholder="选择一个日期" :size="size" :clearable="false" :editable="false" style="margin: 5px 0;"/>
        <el-col :span="24" v-for="item in planChild" v-bind:key="item.name">
            <span class="dou-badge">{{ item.name }}</span>
            <el-row v-for="item1 in item.item" v-bind:key="item1.name" style="align-items: center;">
                <el-col :span="24" style="margin: 8px 0" @click="goUrl(item1.id)">
                    <div style="display: flex;align-items: center;">
                        <svg width="16" v-if="item1.type==0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 13H4V11H12V4L20 12L12 20V13Z"></path></svg>
                        <svg width="16" v-else-if="item1.type==1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M8 20V21.9325C8 22.2086 7.77614 22.4325 7.5 22.4325C7.38303 22.4325 7.26977 22.3915 7.17991 22.3166L3.06093 18.8841C2.84879 18.7073 2.82013 18.392 2.99691 18.1799C3.09191 18.0659 3.23264 18 3.38103 18L18 18C19.1046 18 20 17.1046 20 16V8H22V16C22 18.2091 20.2091 20 18 20H8ZM16 2.0675C16 1.79136 16.2239 1.5675 16.5 1.5675C16.617 1.5675 16.7302 1.60851 16.8201 1.68339L20.9391 5.11587C21.1512 5.29266 21.1799 5.60794 21.0031 5.82008C20.9081 5.93407 20.7674 5.99998 20.619 5.99998L6 6C4.89543 6 4 6.89543 4 8V16H2V8C2 5.79086 3.79086 4 6 4H16V2.0675ZM11 8H13V16H11V10H9V9L11 8Z"></path></svg>
                        <svg width="16" v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M6 4H21C21.5523 4 22 4.44772 22 5V12H20V6H6V9L1 5L6 1V4ZM18 20H3C2.44772 20 2 19.5523 2 19V12H4V18H18V15L23 19L18 23V20Z"></path></svg>
                        <span style="margin-left: 3px;">{{ item1.name }}</span>
                    </div>
                </el-col>
                <el-col :span="24">
                    <el-slider v-model="item1.value" :step="10" show-stops :disabled="!today" :min="item1.min" show-input  size="small"/>
                </el-col>
                <el-col :span="24" style="margin-top: 5px;">
                    <el-form>
                        <el-form-item>
                            <el-input type="textarea" rows="2" v-model="item1.memo" :disabled="!today"/>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <hr />
        </el-col>
    </el-row>
    <el-row style="margin-top:10px">
        <el-col :span="24">
            <span class="dou-badge">总结</span>
            <el-row>
                <el-col :span="24">
                    <el-form>
                        <el-form-item>
                            <el-input type="textarea" rows="3" v-model="formInfo.memo" placeholder="请输入今日总结" :disabled="!today"/>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
    <el-row style="margin-top:10px" v-if="today">
        <el-col :span="24">
            <span class="dialog-footer">
                <el-button type="primary" @click="submitDialog">
                    提交
                </el-button>
            </span>
        </el-col>
    </el-row>
</template>
<script setup>
import { ref, onMounted, defineEmits, defineProps, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import api from '../../../api/api.js';
const props = defineProps(['entityName', "id"]);
const router = useRouter();
const store = useStore();
const emit = defineEmits(['updateEvent']);
const planChild = ref([]);//子列表
const formInfo = ref({ memo: "" });
const choosedDate=ref(new Date());
const today=ref(true);
onMounted(() => {
    pageInit();
})
watch(() => props.id, () => {
    pageInit();
});
watch(() => choosedDate, () => {
    pageInit();
});
const pageInit = async () => {
    let tempExtData = await api.GetDaily(choosedDate.value);
    planChild.value = tempExtData.plan;
    if (tempExtData.memo != null) {
        formInfo.value.memo = tempExtData.memo;
    }else{
        formInfo.value.memo ="";
    }
}
const saveChildPlan = () => {
    router.push('/ps/plan/0?pid=' + props.id);
}
const showRowInfo = (e) => {
    let tempId = e.id;
    router.push('/pi/plan/' + tempId);
    emit("updateEvent", true);
    //切换标签页
    store.commit('generic/SET_CHILDUPDATETAB', true);
}
const submitDialog = async () => {
    let planArr = [];
    planChild.value.forEach(m => {
        var items = m.item;
        items.forEach(n => {
            var tempObj = {};
            tempObj.id = n.id;
            if (n.value != null) {
                tempObj.value = n.value;
            } else {
                tempObj.value = 0;
            }
            tempObj.memo = n.memo;
            planArr.push(tempObj);
        });
    });
    try {
        await api.SaveDaily(planArr, formInfo.value.memo);
        ElMessage({
            message: "操作成功",
            type: 'success'
        })
    } catch (e) {
        console.log(e);
        ElMessage({
            message: e.response.data,
            type: 'error'
        })
    }
}
const updateDate=()=>{
    let now=new Date();
    if(now.toLocaleDateString()!=choosedDate.value.toLocaleDateString()){
        today.value=false;
    }else{
        today.value=true;
    }
    pageInit();
}
const goUrl=(id)=>{
    router.push('/pi/plan/' + id);
}
</script>
<style scoped>
hr {
    border-top: #dcdfe6;
}
</style>