<template>
    <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
            主页
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
</template>
<script setup>
import { defineProps, ref, toRefs, watch } from 'vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
const props = defineProps({
    name: {
        type: String
    },
    prefixStr: {
        type: String,
        default: ''
    },
    suffixStr: {
        type: String,
        default: ''
    }
});
const store = useStore();
const douTable = computed(() => store.state.user.douTable);
const { name, prefixStr, suffixStr } = toRefs(props);
const title = ref(name.value);
const GetName = () => {
    if (name.value != null) {
        let tempObj = douTable.value[name.value];
        if (tempObj) {
            title.value = tempObj.name;
        }
    }
    title.value = prefixStr.value + title.value + suffixStr.value;
}
GetName();
// 使用watch来观察name的变化  
watch(() => props.name, () => {
    GetName();
})
</script>