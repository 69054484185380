<template>
  <el-row>
    <el-col :span="24">
      <GlobalBreadcrumb :name="'保存笔记'"></GlobalBreadcrumb>
    </el-col>
  </el-row>
  <el-row style="margin-top: 10px;">
    <el-col :span="24">
      <el-form :model="form" :rules="rules" ref="formRef">
        <el-form-item label="标题">
          <el-input v-model="form.Name" />
        </el-form-item>
        <el-form-item label="类型" prop="Type">
          <el-select v-model="form.Type" filterable allow-create placeholder="请输入或选择">
            <el-option v-for="item in formTypeList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
  <span class="dou-badge">段落编辑</span>
  <hr />
  <el-row style="margin-top: 10px;" class="sticky-div">
    <el-col :span="24">
      <div style="border: 1 solid black;margin: 10px;">
        <div style="display: flex;justify-items: center;">
          <el-select style="max-width: 175px;" v-model="selectedGroupType" :disabled="selectedIndex === null"
            placeholder="请选择类型" @change="updateGroupType">
            <el-option v-for="item in enumLanguage" :key="item.value" :label="item.key" :value="item.value" />
          </el-select>
          <div style="display: flex; justify-items: center;" v-if="selectedIndex !== null">
            <span style="margin:5px;display: flex;justify-content: center;" @click="insertBefore(selectedIndex)">
              <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path
                  d="M20 13C20.5523 13 21 13.4477 21 14V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V14C3 13.4477 3.44772 13 4 13H20ZM19 15H5V19H19V15ZM12 1C14.7614 1 17 3.23858 17 6C17 8.76142 14.7614 11 12 11C9.23858 11 7 8.76142 7 6C7 3.23858 9.23858 1 12 1ZM13 3H11V4.999L9 5V7L11 6.999V9H13V6.999L15 7V5L13 4.999V3Z">
                </path>
              </svg>
            </span>
            <span style="display: flex;justify-content: center;align-items: center;">|</span>
            <span style="margin:5px;display: flex;justify-content: center;" @click="insertAfter(selectedIndex)">
              <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path
                  d="M12 13C14.7614 13 17 15.2386 17 18C17 20.7614 14.7614 23 12 23C9.23858 23 7 20.7614 7 18C7 15.2386 9.23858 13 12 13ZM13 15H11V16.999L9 17V19L11 18.999V21H13V18.999L15 19V17L13 16.999V15ZM20 3C20.5523 3 21 3.44772 21 4V10C21 10.5523 20.5523 11 20 11H4C3.44772 11 3 10.5523 3 10V4C3 3.44772 3.44772 3 4 3H20ZM5 5V9H19V5H5Z">
                </path>
              </svg>
            </span>
            <span style="display: flex;justify-content: center;align-items: center;">|</span>
            <span style="margin:5px;display: flex;justify-content: center;" @click="formatContent(selectedIndex)">
              <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M3 4H21V6H3V4ZM3 19H21V21H3V19ZM11 14H21V16H11V14ZM11 9H21V11H11V9ZM7 12.5L3 16V9L7 12.5Z">
                </path>
              </svg>
            </span>
            <span style="display: flex;justify-content: center;align-items: center;">|</span>
            <span style="margin:5px;display: flex;justify-content: center;" @click="removeGroup(selectedIndex)"
              v-if="groups.length != 1">
              <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path
                  d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z">
                </path>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
  <el-row style="margin-top: 10px;">
    <el-col :span="24">
      <el-form :model="formContent" ref="formContentRef">
        <el-form-item v-for="(group, index) in groups" :key="group.id" @click="selectGroup(index)" class="group">
          <el-input v-model="group.Content" type="textarea" rows="4" />
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="24">
      <span class="dialog-footer">
        <el-button type="primary" @click="submitForm">
          提交
        </el-button>
      </span>
    </el-col>
  </el-row>
</template>
<script setup>
import { ref, onMounted, computed, defineProps } from 'vue';
import { useRouter } from 'vue-router';
// import { mapMutations, mapState } from 'vuex';
import api from '../../../api/api.js';
import { ElMessage } from 'element-plus';
// import dayjs from 'dayjs';

// UUID 生成函数
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
const route = useRouter();
const existInfo = ref({})//查询已存在类型
const formTypeList = ref([]);
const form = ref({});
const formContent = ref({});
const props = defineProps(["id"]);
let languageList = [];
languageList.push({ key: "正文", value: "正文" });
languageList.push({ key: "一级段落", value: "一级段落" });
languageList.push({ key: "二级段落", value: "二级段落" });
languageList.push({ key: "三级段落", value: "三级段落" });
languageList.push({ key: "C#", value: "C#" });
languageList.push({ key: "JavaScript", value: "JavaScript" });
languageList.push({ key: "HTML", value: "HTML" });
languageList.push({ key: "CSS", value: "CSS" });
languageList.push({ key: "SQL", value: "SQL" });
languageList.push({ key: "XML", value: "XML" });
languageList.push({ key: "JSON", value: "JSON" });
languageList.push({ key: "Java", value: "Java" });
languageList.push({ key: "其他", value: "其他" });
const enumLanguage = ref(languageList);
onMounted(async () => {
  existInfo.value = await api.GetNoteExistInfo();
  existInfo.value.type.forEach(m => {
    formTypeList.value.push(m);
  });
  if (props.id != null && props.id != "0") {
    const response = await api.GetNoteById(props.id);
    form.value.Name = response.name;
    form.value.Type = response.type;
    var tempParagraphs = response.paragraphs.map(p => ({
      Id: p.id,
      Content: p.content,
      Type: p.type,
      PrevId: p.prevId,
      NextId: p.nextId
    }));
    groups.value = sortGroups(tempParagraphs);
  }
});

// 初始化组
const groups = ref([
  {
    Id: generateUUID(), // 使用自定义UUID生成函数
    Content: '', // 初始内容为空
    Type: "正文",
    PrevId: null,
    NextId: null
  }
])

// 当前选择的组索引
const selectedIndex = ref(null)

// 计算当前选中组的类型
const selectedGroupType = computed({
  get() {
    if (selectedIndex.value !== null) {
      return groups.value[selectedIndex.value].Type
    }
    return '正文'
  },
  set(value) {
    if (selectedIndex.value !== null) {
      groups.value[selectedIndex.value].Type = value
    }
  }
})

// 更新组的类型
const updateGroupType = () => {
  if (selectedIndex.value !== null) {
    groups.value[selectedIndex.value].type = selectedGroupType.value
  }
}

// 选择组
const selectGroup = (index) => {
  selectedIndex.value = index
}

// 在指定位置前插入新组
const insertBefore = (index) => {
  const newGroup = {
    Id: generateUUID(),
    Content: '',
    Type: '正文',
    PrevId: index > 0 ? groups.value[index - 1].Id : null,
    NextId: groups.value[index].Id
  }

  // 更新当前组的 PrevId 和前一个组的 NextId
  if (index > 0) {
    groups.value[index - 1].NextId = newGroup.Id
  }
  groups.value[index].PrevId = newGroup.Id

  groups.value.splice(index, 0, newGroup)
}

// 在指定位置后插入新组
const insertAfter = (index) => {
  const newGroup = {
    Id: generateUUID(),
    Content: '',
    Type: '正文',
    PrevId: groups.value[index].Id,
    NextId: index < groups.value.length - 1 ? groups.value[index + 1].Id : null
  }

  // 更新当前组的 NextId 和下一个组的 PrevId
  if (index < groups.value.length - 1) {
    groups.value[index + 1].PrevId = newGroup.Id
  }
  groups.value[index].NextId = newGroup.Id

  groups.value.splice(index + 1, 0, newGroup)
}

// 删除指定组
const removeGroup = (index) => {
  if (groups.value.length > 1) {
    const PrevId = groups.value[index].PrevId
    const NextId = groups.value[index].NextId

    // 如果当前组有前一个组，更新前一个组的 NextId
    if (PrevId) {
      const prevGroup = groups.value.find(group => group.Id === PrevId)
      prevGroup.NextId = NextId
    }

    // 如果当前组有后一个组，更新后一个组的 PrevId
    if (NextId) {
      const nextGroup = groups.value.find(group => group.Id === NextId)
      nextGroup.PrevId = PrevId
    }

    // 删除当前组
    groups.value.splice(index, 1)

    // 如果删除的是当前选中的组，取消选择
    if (selectedIndex.value === index) {
      selectedIndex.value = null
    }
  }
}

// 格式化内容，基于选择的格式 (JSON 或 XML)
const formatContent = (index) => {
  let type = groups.value[index].Type;
  switch (type) {
    case 'JavaScript':
    case 'C#':
    case 'JSON': {
      formatJSONContent(index)
    } break;
    case 'XML': {
      formatXMLContent(index)
    } break;
  }
}

// JSON 格式化逻辑
const formatJSONContent = (index) => {
  const indentSize = 3
  let indentLevel = 0
  const content = groups.value[index].Content

  const formattedContent = content
    .split('\n')
    .map(line => {
      const trimmedLine = line.trim()

      if (trimmedLine.startsWith('}')) {
        indentLevel = Math.max(indentLevel - 1, 0)
      }

      const indentedLine = ' '.repeat(indentLevel * indentSize) + trimmedLine

      if (trimmedLine.endsWith('{')) {
        indentLevel++
      }

      return indentedLine
    })
    .join('\n')

  groups.value[index].Content = formattedContent
}

// XML 格式化逻辑
const formatXMLContent = (index) => {
  const indentSize = 3
  let indentLevel = 0
  const content = groups.value[index].Content

  const formattedContent = content
    .split('\n')
    .map(line => {
      const trimmedLine = line.trim()

      if (trimmedLine.startsWith('</')) {
        indentLevel = Math.max(indentLevel - 1, 0)
      }

      const indentedLine = ' '.repeat(indentLevel * indentSize) + trimmedLine

      if (trimmedLine.startsWith('<') &&
        !trimmedLine.startsWith('</') &&
        !trimmedLine.endsWith('/>') &&
        !trimmedLine.includes('</')) {
        indentLevel++
      }

      return indentedLine
    })
    .join('\n')

  groups.value[index].Content = formattedContent
}

const sortGroups = (groupList) => {
  const idMap = new Map(groupList.map(g => [g.Id, g]));
  let startGroup = groupList.find(g => g.PrevId === null);

  const sorted = [];
  while (startGroup) {
    sorted.push(startGroup);
    startGroup = idMap.get(startGroup.NextId);
  }
  return sorted;
};

const submitForm = async () => {
  let tempObj = {};
  tempObj.Name = form.value.Name;
  tempObj.Type = form.value.Type;
  tempObj.Paragraphs = groups.value;
  if (props.id != null || props.id != '0') {
    tempObj.Id = props.id;
  }
  try {
    await api.SaveNote(tempObj);
    route.back();
  } catch (error) {
    // 处理登录失败的逻辑
    ElMessage.error("保存失败！" + error.response.data);
  }
}
</script>
<style scoped>
.sticky-div {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  /* 确保它在其他元素之上 */
}
</style>