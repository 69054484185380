<template>
    <span class="dou-badge">今日账单</span>
    <el-row style="margin:10px 0">
        <el-col :span="12">
            <div class="grid-content ep-bg-purple d-box" @click="goUrl('BillDayReport','type=2')">
                <b>{{ bill.inAmount }}</b>
                <span style="font-size: 14px;">本日收入</span>
            </div>
        </el-col>
        <el-col :span="12">
            <div class="grid-content ep-bg-purple d-box" @click="goUrl('BillDayReport','type=1')">
                <b>{{ bill.outAmount }}</b>
                <span style="font-size: 14px;">本日支出</span>
            </div>
        </el-col>
    </el-row>
    <span class="dou-badge">今日提醒</span>
    <el-row style="margin:10px 0">
        <el-col :span="24">
            <div style="font-size: 14px;">
                猫咪还有 <b>200</b> 天过生日。
            </div>
        </el-col>
    </el-row>
    <span class="dou-badge">天气信息</span>
    <el-row style="margin:10px 0">
        <el-col :span="24">
            <div style="font-size: 14px;">
                山东省青岛市 晴 25℃-35℃
            </div>
        </el-col>
    </el-row>
    <span class="dou-badge">日志</span>
    <el-row style="margin:10px 0">
        <el-col :span="24">
            <div style="font-size: 14px;color:#409eff;display: flex" @click="goUrl('DiarySave')">
                <svg v-if="diary == ''" xmlns="http://www.w3.org/2000/svg" width=20 viewBox="0 0 24 24"
                    fill="rgba(64,158,255,1)">
                    <path
                        d="M6.93912 14.0328C6.7072 14.6563 6.51032 15.2331 6.33421 15.8155C7.29345 15.1189 8.43544 14.6767 9.75193 14.5121C12.2652 14.198 14.4976 12.5385 15.6279 10.4537L14.1721 8.99888L15.5848 7.58417C15.9185 7.25004 16.2521 6.91614 16.5858 6.58248C17.0151 6.15312 17.5 5.35849 18.0129 4.2149C12.4197 5.08182 8.99484 8.50647 6.93912 14.0328ZM17 8.99739L18 9.99669C17 12.9967 14 15.9967 10 16.4967C7.33146 16.8303 5.66421 18.6636 4.99824 21.9967H3C4 15.9967 6 1.99669 21 1.99669C20.0009 4.99402 19.0018 6.99313 18.0027 7.99402C17.6662 8.33049 17.3331 8.66382 17 8.99739Z">
                    </path>
                </svg>
                <span v-if="diary == ''">去填写</span>
                <span v-else>今日完成度：{{ diary }}</span>
            </div>
        </el-col>
    </el-row>
    <span class="dou-badge">当前执行</span>
    <el-card style="margin: 10px 0">
        <div v-for="(item, index) in nowData" v-bind:key="item.id" style="font-size: 14px;"
            @click="showDateExecInfo(item.id)">
            <div style="margin: 5px 0;display: flex;justify-items: center;">
                <span class="d_span d_bd_blue" v-if="item.status == 0" style="margin-left: 0px">{{ item.statusName
                    }}</span>
                <span class="d_span d_bd_green" v-if="item.status == 1 && item.goalType!=3" style="margin-left: 0px">{{ item.statusName
                    }}</span>
                <span class="d_span d_bd_darkred" v-if="item.status == 1 && item.goalType==3" style="margin-left: 0px">{{ item.statusName
                    }}</span>
                <span class="d_span d_bd_darkred" v-if="item.status == 2" style="margin-left: 0px">{{ item.statusName
                    }}</span>
                <span class="d_span d_bd_gray" v-if="item.status == 3" style="margin-left: 0px">{{ item.statusName
                    }}</span>
                <span class="dou-badge">{{ item.startTime }} - {{ item.endTime }}</span>
            </div>
            <div style="margin: 3px 0;" v-if="item.goal != null">[目标] {{ item.goal }}</div>
            <div style="margin: 3px 0;font-weight: bold;" v-html="item.content.split('\n').join('<br/>')"></div>
            <div style="margin: 3px 0;" v-if="item.memo != null">
                <span v-html="'[备注] ' + item.memo.split('\n').join('<br/>')"></span>
            </div>
            <hr v-if="index != nowData.length - 1" />
        </div>
        <div v-if="nowData.length == 0"><span class="dou-badge">暂无内容</span></div>
    </el-card>
    <span class="dou-badge">日程待做</span>
    <el-tabs class="demo-tabs" v-model="todo.active" @tab-change="GetTodoList" v-show="false">
        <el-tab-pane label="今天" name="today">
            <DouTimeline :formData="todo.today" :name="今日待做" :entityName="todo.entity"></DouTimeline>
        </el-tab-pane>
        <el-tab-pane label="近七天" name="seven">
            <DouTimeline :formData="todo.seven" :name="今日待做" :entityName="todo.entity"></DouTimeline>
        </el-tab-pane>
        <el-tab-pane label="以后" name="after">
            <DouTimeline :formData="todo.after" :name="今日待做" :entityName="todo.entity"></DouTimeline>
        </el-tab-pane>
        <el-tab-pane label="已完成" name="completed">
            <DouTimeline :formData="todo.completed" :name="今日待做" :entityName="todo.entity"></DouTimeline>
        </el-tab-pane>
    </el-tabs>
    <div style="overflow-x: auto;">
        <el-calendar v-model="calendarChoosedDate">
            <template #date-cell="{ data }">
                <div>
                    <div>{{ data.date.getDate() }}</div>
                    <div v-if="calendarData.filter(m => m.date == data.day).length > 0">
                        <span style="font-size: 12px;word-break:keep-all;text-wrap:nowrap;overflow:hidden;display:block;white-space: nowrap;"
                            v-bind:key="item.id" v-for="item in calendarData.filter(m => m.date == data.day)[0].exec">
                            {{ item.name }}</span>
                    </div>
                </div>
            </template>
        </el-calendar>
    </div>
    <el-dialog v-model="showDateInfoDialog"
        :title="calendarChoosedDate == '' ? '' : calendarChoosedDate.toLocaleDateString() + '执行列表'" width="90%">
        <div style="color: #409eff;padding-bottom: 10px;" @click="addNormalExec">+点击添加一般执行</div>
        <el-timeline>
            <el-timeline-item v-for="(line, index) in dateInfo" :key="index" :type="line.type"
                @click="showDateExecInfo(line.id)" :color="line.color" :size="line.size" :hollow="line.hollow"
                :hide-timestamp="line.hideTimestamp" :timestamp="line.timestamp" placement="top">
                <div style="display: flex;justify-items: center;margin: 1px 0;">
                    <span>[状态] </span>
                    <span class="d_span d_bd_blue" style="height: fit-content;" v-if="line.status == 0">{{
            line.statusName }}</span>
                    <span class="d_span d_bd_green" style="height: fit-content;" v-if="line.status == 1 && line.goalType!=3">{{
            line.statusName }}</span>
                    <span class="d_span d_bd_darkred" style="height: fit-content;" v-if="line.status == 1 && line.goalType==3">{{
            line.statusName }}</span>
                    <span class="d_span d_bd_darkred" style="height: fit-content;" v-if="line.status == 2">{{
            line.statusName }}</span>
                    <span class="d_span d_bd_gray" style="height: fit-content;" v-if="line.status == 3">{{
            line.statusName }}</span>
                </div>
                <div><span v-html="'[内容] '+line.content.split('\n').join('<br/>')"></span></div>
                <div v-if="line.goal != null" style="display: flex;justify-items: center;padding: 1px 0;">
                    <span>[目标] {{ line.goal }}</span>
                </div>
                <div v-if="line.memo != null" style="display: flex;justify-items: center;padding: 1px 0;">
                    <span v-html="'[备注] '+line.memo.split('\n').join('<br/>')"></span>
                </div>
            </el-timeline-item>
        </el-timeline>
        <div v-if="dateInfo.length==0"><span class="dou-badge">暂无内容</span></div>
    </el-dialog>
</template>
<script>
import api from '../../api/api.js'; // 替换成你的实际路径
import DouTimeline from '../Sub/DouTimeline.vue';
import { ElMessage } from 'element-plus';
export default {
    props: {

    },
    data() {
        return {
            todo: {
                active: "today",
                entity: "Todo"
            },
            diary: "1",
            calendarData: [{ date: "", exec: [] }],
            calendarChoosedDate: "",
            showDateInfoDialog: false,//是否展示详情
            dateInfo: [],
            nowData: [],
            bill:{}//账单相关
        }
    },
    created() {

    },
    mounted() {
        //this.GetTodoList("today");
        this.GetHome();
    },
    watch: {
        calendarChoosedDate(newVal, oldVal) {
            if (oldVal != "") {
                if (newVal.getFullYear() != oldVal.getFullYear() || newVal.getMonth() != oldVal.getMonth()) {
                    this.GetCalendar(newVal.toLocaleDateString());
                } else {
                    this.showDateInfo(newVal);
                }
            } else {
                var nowMonth = new Date().getMonth();
                if (newVal.getMonth() == nowMonth && oldVal == "") {
                    this.showDateInfo(newVal);
                } else {
                    this.GetCalendar(newVal.toLocaleDateString());
                }
            }
        }
    },
    components: {
        DouTimeline
    },
    methods: {
        async GetTodoList(name) {
            try {
                const response = await api.GetTodoList(name);
                //拼接数据源
                var tempArr = [];
                response.todo.forEach(element => {
                    var tempData = {};
                    tempData.content = element.name;
                    tempData.timestamp = element.date;
                    if (element.id != null) {
                        tempData.id = element.id;
                    }
                    if (element.date != null && element.date != "") {
                        tempData.color = '#0bbd87';
                        tempData.size = 'large';
                    } else {
                        tempData.hideTimestamp = true;
                    }
                    tempArr.push(tempData);
                });
                this.todo[name] = tempArr;
                if (response.diary != null) {
                    this.diary = response.diary;
                }
            } catch (error) {
                // 处理登录失败的逻辑
                console.error('Login failed:', error);
            }
        },
        async GetHome() {
            try {
                const response = await api.GetHome();
                //拼接数据源
                this.calendarData = response.calendar;
                this.nowData = response.now;
                this.bill = response.bill;
            } catch (error) {
                // 处理登录失败的逻辑
                ElMessage.error("初始化数据失败！" + error.response.data);
            }
        },
        async GetCalendar(date) {
            try {
                const response = await api.GetCalendar(date);
                //拼接数据源
                this.calendarData = response;
            } catch (error) {
                // 处理登录失败的逻辑
                ElMessage.error("获取日历数据失败！" + error.response.data);
            }
        },
        goUrl(url,param1) {
            var paramObj={};
            if(param1!=null){
                var param1Arr=param1.split('=');
                paramObj[param1Arr[0]]=param1Arr[1];
            }
            if(Object.keys(paramObj).length==0){
                this.$router.push({ name: url });
            }else{
                this.$router.push({ name: url,query:paramObj});
            }
        },
        showDateInfo(newVal) {
            var newValDate = newVal.toLocaleDateString();
            let now = new Date();
            var dateInfo = this.calendarData.filter(m => new Date(m.date).toLocaleDateString() == newValDate);
            if (dateInfo.length > 0) {
                var tempData = [];
                for (var i = 0; i < dateInfo[0].exec.length; i++) {
                    let item = dateInfo[0].exec[i];
                    var tempObj = {};
                    tempObj.timestamp = item.startTime + " - " + item.endTime;
                    tempObj.goal = item.goal;
                    tempObj.goalType = item.goalType;
                    tempObj.content = item.name;
                    tempObj.id = item.id;
                    tempObj.status = item.status;
                    tempObj.statusName = item.statusName;
                    tempObj.memo = item.memo;
                    let tempItemStartDate = new Date(dateInfo[0].date + " " + item.startTime);
                    let tempItemEndDate = new Date(dateInfo[0].date + " " + item.endTime);
                    if (tempItemEndDate >= tempItemStartDate) {
                        if (now >= tempItemStartDate && now <= tempItemEndDate) {
                            tempObj.color = "green";
                        }
                    } else {
                        tempItemStartDate.setDate(tempItemStartDate.getDate() + 1);
                        if (now >= tempItemEndDate && now <= tempItemStartDate) {
                            tempObj.color = "green";
                        }
                    }
                    tempData.push(tempObj);
                }
                this.dateInfo = tempData;
            }
            this.showDateInfoDialog = true;
        },
        showDateExecInfo(id) {
            this.$router.push('/pi/goalexecution/' + id);
        },
        addNormalExec() {
            var dateStr = this.calendarChoosedDate.toLocaleDateString();
            this.$router.push('/ps/goalexecution/0?date=' + dateStr);
        }
    }
}
</script>
<style>
.el-timeline {
    padding-inline-start: 0px;
}

.demo-tabs>.el-tabs__content {
    margin: 0px;
}

.el-timeline-item {
    padding-bottom: 20px;
}

.el-calendar-day {
    overflow: hidden;
}

hr {
    border-top: #dcdfe6;
}
</style>
