<template>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">功能列表</span>
            <div class="d-tab d-center">
                <el-button type="primary" @click="saveExec">新增执行</el-button>
                <el-button type="primary">调整状态</el-button>
            </div>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">执行列表</span>
            <el-table :data="execList" :border="true" @row-click="goExec">
                <el-table-column label="#" width="40">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <span>{{ scope.row.index }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="日期" width="98">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <span>{{ scope.row.date }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="状态" width="80">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <span class="d_span" v-if="scope.row.status == 4">未开始</span>
                            <span class="d_span d_bd_blue" v-if="scope.row.status == 0">进行中</span>
                            <span class="d_span d_bd_green" v-if="scope.row.status == 1">已完成</span>
                            <span class="d_span d_bd_darkred" v-if="scope.row.status == 2">已失败</span>
                            <span class="d_span d_bd_gray" v-if="scope.row.status == 3">已取消</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="内容" width="200">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <span>{{ scope.row.content }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="预计时刻" width="160">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <span>{{ scope.row.starttime }} - {{ scope.row.endtime }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">近一月趋势</span>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24" style="height: 300px;">
            <v-chart class="chart" :option="option1" autoresize />
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">测试</span>
            <div contenteditable="true" style="height: 300px">2333</div>
        </el-col>
    </el-row>
    <el-dialog v-model="showSaveExec" title="添加执行" width="90%">
        <el-form label-width="68px">
            <el-form-item label="执行日期">
                <el-date-picker v-model="formExec.Date" type="date" placeholder="请选择执行日期" :editable="false"
                    style="width: 100%" :clearable="false" />
            </el-form-item>
            <el-form-item label="开始时刻">
                <el-time-picker v-model="formExec.StartTime" type="time" placeholder="请选择执行日期" :editable="false"
                    style="width: 100%" :clearable="false" :format="'HH:mm'" />
            </el-form-item>
            <el-form-item label="结束时刻">
                <el-time-picker v-model="formExec.EndTime" type="time" placeholder="请选择执行日期" :editable="false"
                    style="width: 100%" :clearable="false" :format="'HH:mm'" />
            </el-form-item>
            <el-form-item label="内容">
                <el-input rows="3" v-model="formExec.Content" type="textarea" />
            </el-form-item>
        </el-form>
        <span class="dialog-footer">
            <el-button type="primary" @click="saveExecSubmit">
                提交
            </el-button>
        </span>
    </el-dialog>
</template>
<script setup>
import { ref, defineProps, onMounted, watch, defineEmits } from 'vue';
import { ElMessage } from 'element-plus';
const props = defineProps(['entityName', "id"]);
import { useRouter } from 'vue-router';
import api from '../../../api/api.js';
const showSaveExec = ref(false);
const formExec = ref({});
const router = useRouter();
const emit = defineEmits(['updateEvent']);
const execList = ref([]);
const option1 = ref();

import { use } from 'echarts/core';
import {
    LineChart,
    BarChart
} from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    LegendComponent,
    MarkLineComponent
} from 'echarts/components';
import {
    CanvasRenderer
} from 'echarts/renderers';
import { UniversalTransition } from 'echarts/features';
use([
    LineChart,
    BarChart,
    GridComponent,
    TooltipComponent,
    LegendComponent,
    CanvasRenderer,
    UniversalTransition,
    MarkLineComponent
]);
import VChart from 'vue-echarts';

onMounted(() => {
    pageInit();
})
watch(() => props.id, () => {
    pageInit();
});
const pageInit = async () => {
    let tempExtData = await api.GetGoalExt(props.id);
    execList.value = tempExtData.exec;
    option1.value=tempExtData.option1;
}
const saveExec = () => {
    showSaveExec.value = true;
}
const getTimeStr = (time) => {
    var timeStr = time.toString();
    if (time < 10) {
        timeStr = "0" + timeStr;
    }
    return timeStr;
}
const saveExecSubmit = async () => {
    let tempObj = {};
    tempObj.GoalId = props.id;
    tempObj.StartTime = getTimeStr(formExec.value.StartTime.getHours()) + ":" + getTimeStr(formExec.value.StartTime.getMinutes()) + ":00";
    tempObj.EndTime = getTimeStr(formExec.value.EndTime.getHours()) + ":" + getTimeStr(formExec.value.EndTime.getMinutes()) + ":00";
    tempObj.Content = formExec.value.Content;
    tempObj.Date = formExec.value.Date.toLocaleDateString();
    try {
        await api.SaveGoalExec(tempObj);
        ElMessage({
            message: "操作成功",
            type: 'success'
        })
        showSaveExec.value = false;
        pageInit();
        emit("updateEvent", true);
    } catch (e) {
        console.log(e);
        ElMessage({
            message: e.response.data,
            type: 'error'
        })
    }
}
const goExec = (obj) => {
    router.push('/pi/goalexecution/' + obj.id);
}
</script>