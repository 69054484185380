<template>
  <el-row>
    <el-col :col="24">
      <span class="dou-badge">功能列表</span>
      <div class="d-tab d-center">
        <el-button type="primary" @click="saveChildPlan">新建子计划</el-button>
        <el-button type="primary" @click="saveGoal">添加目标</el-button>
        <el-button type="primary">调整状态</el-button>
      </div>
    </el-col>
  </el-row>
  <el-row>
    <el-col :col="24">
      <span class="dou-badge">子计划列表</span>
      <el-table :data="planChild" stripe style="width: 100%" @row-click="showRowInfo">
        <el-table-column width="150" prop="name" label="名称" />
        <el-table-column width="100" prop="starttime" label="开始时间" />
        <el-table-column width="100" prop="endtime" label="结束时间" />
      </el-table>
    </el-col>
  </el-row>
  <el-row>
    <el-col :col="24">
      <span class="dou-badge">目标一览</span>
      <div>
        <el-input v-model="filterText" style="width: 240px;margin-bottom: 3px;" placeholder="请输入搜索内容" />
        <div style="overflow-x: auto;">
          <el-tree ref="treeRef" class="filter-tree" :data="treeData" :props="defaultProps" default-expand-all
            :filter-node-method="filterNode" :expand-on-click-node="false" @node-click="goLeaf" />
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script setup>
import { ref, onMounted, defineEmits, defineProps, watch } from 'vue';
import { ElTree } from 'element-plus'
const props = defineProps(['entityName', "id"]);
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import api from '../../../api/api.js';
import dayjs from 'dayjs';
const router = useRouter();
const store = useStore();
const emit = defineEmits(['updateEvent']);
const planChild = ref([]);//子列表

//Tree
// 移除 TypeScript 接口定义  
const filterText = ref('');
const treeRef = ref(null); // 在 Vue 3 中，ref 初始值通常为 null 或具体的初始值  
const defaultProps = {
  children: 'children',
  label: 'label',
  class: 'class'
};
watch(filterText, (val) => {
  if (treeRef.value) {
    treeRef.value.filter(val);
  }
});
// 由于移除了 TypeScript 的类型定义，filterNode 函数的参数类型也不再明确  
// 但这不会影响 JavaScript 的运行  
const filterNode = (value, data) => {
  if (!value) return true;
  return data.label.includes(value);
};
// 数据同样不需要 TypeScript 类型定义  
const treeData = ref([]);

onMounted(() => {
  pageInit();
})
watch(() => props.id, () => {
  pageInit();
});
const pageInit = async () => {
  let tempExtData = await api.GetPlanExt(props.id);
  planChild.value = [];
  tempExtData.child.forEach(element => {
    element.starttime = new Date(dayjs(element.starttime + "Z")).toLocaleDateString();
    element.endtime = new Date(dayjs(element.endtime + "Z")).toLocaleDateString();
    planChild.value.push(element);
  });
  treeData.value = tempExtData.childTree;
}
const saveChildPlan = () => {
  router.push('/ps/plan/0?pid=' + props.id);
}
const showRowInfo = (e) => {
  let tempId = e.id;
  router.push('/pi/plan/' + tempId);
  emit("updateEvent", true);
  //切换标签页
  store.commit('generic/SET_CHILDUPDATETAB', true);
}
const saveGoal = () => {
  router.push('/ps/goal/0?planid=' + props.id);
}
const goLeaf = (obj) => {
  var tempClass = obj.class;
  let tempId = obj.id;
  switch (tempClass) {
    case "d-tree-plan": {
      router.push('/pi/plan/' + tempId);
      emit("updateEvent", true);
      //切换标签页
      store.commit('generic/SET_CHILDUPDATETAB', true);
    } break;
    case "d-tree-goal": {
      router.push('/pi/goal/' + tempId);
    } break;
  }
}
</script>