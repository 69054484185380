<template>
    <el-upload v-model:file-list="fileList" :action="url" limit="3" multiple="true"
        list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove" accept="image/*"
    capture="camera" auto-upload="false">
        <el-icon>
            <Plus />
        </el-icon>
    </el-upload>
    <el-dialog v-model="dialogVisible">
        <img w-full :src="dialogImageUrl" alt="Preview Image" />
    </el-dialog>
</template>
<script setup>
import { ref } from 'vue'
import { Plus } from '@element-plus/icons-vue';
var urlStr=process.env.VUE_APP_API_BASE_URL+"/api/Upload/upload";
const url=ref(urlStr);
var a=[{
    name: 'food.jpeg',
    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
  }];
const fileList = ref(a);

const dialogImageUrl = ref('')
const dialogVisible = ref(false)

const handleRemove=()=>{};

const handlePictureCardPreview=(uploadFile)=>{
    dialogImageUrl.value = uploadFile.url;
    dialogVisible.value = true;
};
</script>