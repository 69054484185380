<template>
    <el-row>
        <el-col :span="24">
            <GlobalBreadcrumb :name="'笔记'" suffixStr="列表"></GlobalBreadcrumb>
        </el-col>
    </el-row>
    <el-row style="margin-top: 8px;">
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item>
                    <el-input v-model="searchText" />
                </el-form-item>
                <el-form-item>
                    <el-button @click="goPath">
                        <el-icon>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path>
                            </svg>
                        </el-icon>
                    </el-button>
                </el-form-item>
            </el-form>
        </el-col>
    </el-row>
    <div v-for="(item, index) in list" :key="index">
        <el-row style="margin-top:5px" @click="goInfoHref(item.id, $event)">
            <el-col :span="3" v-if="item.icon != null">
                <svg v-if="item.icon == 'eat'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                    fill="rgba(234,113,46,1)">
                    <path
                        d="M8 1.5C8 0.947715 7.55228 0.5 7 0.5C6.44772 0.5 6 0.947715 6 1.5V2.5C6 2.50686 6.00042 2.51285 6.00081 2.51843C6.00385 2.56193 6.00516 2.58063 5.79289 2.79289L5.77277 2.81298C5.50599 3.07912 5 3.58391 5 4.5V5.5C5 6.05228 5.44772 6.5 6 6.5C6.55228 6.5 7 6.05228 7 5.5V4.5C7 4.49314 6.99958 4.48715 6.99919 4.48157C6.99615 4.43807 6.99484 4.41937 7.20711 4.20711L7.22723 4.18702C7.49401 3.92088 8 3.41609 8 2.5V1.5ZM2 9C2 8.44771 2.44772 8 3 8H21C21.5523 8 22 8.44772 22 9V10C22 14.1006 19.5318 17.6248 16 19.1679V20C16 20.5523 15.5523 21 15 21H9C8.44772 21 8 20.5523 8 20V19.1679C4.46819 17.6248 2 14.1006 2 10V9ZM18 0.5C18.5523 0.5 19 0.947715 19 1.5V2.5C19 3.41609 18.494 3.92088 18.2272 4.18702L18.2071 4.20711C17.9948 4.41937 17.9962 4.43807 17.9992 4.48157C17.9996 4.48715 18 4.49314 18 4.5V5.5C18 6.05228 17.5523 6.5 17 6.5C16.4477 6.5 16 6.05228 16 5.5V4.5C16 3.58391 16.506 3.07912 16.7728 2.81298L16.7929 2.79289C17.0052 2.58063 17.0038 2.56193 17.0008 2.51843C17.0004 2.51285 17 2.50686 17 2.5V1.5C17 0.947715 17.4477 0.5 18 0.5ZM13.5 1.5C13.5 0.947715 13.0523 0.5 12.5 0.5C11.9477 0.5 11.5 0.947715 11.5 1.5V2.5C11.5 2.50686 11.5004 2.51285 11.5008 2.51843C11.5038 2.56193 11.5052 2.58063 11.2929 2.79289L11.2728 2.81298C11.006 3.07912 10.5 3.58391 10.5 4.5V5.5C10.5 6.05228 10.9477 6.5 11.5 6.5C12.0523 6.5 12.5 6.05228 12.5 5.5V4.5C12.5 4.49314 12.4996 4.48715 12.4992 4.48157C12.4962 4.43807 12.4948 4.41937 12.7071 4.20711L12.7272 4.18702C12.994 3.92088 13.5 3.41609 13.5 2.5V1.5Z">
                    </path>
                </svg>
                <svg v-else-if="item.icon == 'reside'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                    fill="rgba(240,187,64,1)">
                    <path
                        d="M20 20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V11L1 11L11.3273 1.6115C11.7087 1.26475 12.2913 1.26475 12.6727 1.6115L23 11L20 11V20ZM11 13V19H13V13H11Z">
                    </path>
                </svg>
                <svg v-else-if="item.icon == 'shopping'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                    fill="rgba(221,70,177,1)">
                    <path
                        d="M6.00488 9H19.9433L20.4433 7H8.00488V5H21.7241C22.2764 5 22.7241 5.44772 22.7241 6C22.7241 6.08176 22.7141 6.16322 22.6942 6.24254L20.1942 16.2425C20.083 16.6877 19.683 17 19.2241 17H5.00488C4.4526 17 4.00488 16.5523 4.00488 16V4H2.00488V2H5.00488C5.55717 2 6.00488 2.44772 6.00488 3V9ZM6.00488 23C4.90031 23 4.00488 22.1046 4.00488 21C4.00488 19.8954 4.90031 19 6.00488 19C7.10945 19 8.00488 19.8954 8.00488 21C8.00488 22.1046 7.10945 23 6.00488 23ZM18.0049 23C16.9003 23 16.0049 22.1046 16.0049 21C16.0049 19.8954 16.9003 19 18.0049 19C19.1095 19 20.0049 19.8954 20.0049 21C20.0049 22.1046 19.1095 23 18.0049 23Z">
                    </path>
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(70,146,221,1)">
                    <path
                        d="M20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22ZM8 9V11H16V9H8ZM8 13V15H16V13H8Z">
                    </path>
                </svg>
            </el-col>
            <el-col :span="calculateSpanCount(item)">
                <div>
                    <div style="display: flex;justify-items: center">
                        <span class="d_span" style="margin-left: 0;" v-if="item.status == 0">未开始</span>
                        <span class="d_span d_bd_green" style="margin-left: 0;" v-if="item.status == 1">已完成</span>
                        <span class="d_span d_bd_blue" style="margin-left: 0;" v-if="item.status == 2">进行中</span>
                        <span class="d_span d_bd_orange" style="margin-left: 0;" v-if="item.status == 3">已超时</span>
                        <span class="d_span d_bd_darkred" style="margin-left: 0;" v-if="item.status == 4">已失败</span>
                        <span class="d_span d_bd_gray" style="margin-left: 0;" v-if="item.status == 5">已取消</span>
                        <span style="font-size: 14px;">{{ item.name }}</span>
                    </div>
                    <div v-if="item.memo1 != null"><span style="color: gray;font-size: 12px;">{{ item.memo1 }}</span>
                    </div>
                    <div v-if="item.memo2 != null"><span style="color: gray;font-size: 12px;">{{ item.memo2 }}</span>
                    </div>
                </div>
            </el-col>
            <el-col :span="6" v-if="item.rname != null" align="right">
                <b :class="{ 'd-orange': (props.entityName == 'Bill' && item.rname > 0) }">{{ item.rname }}</b>
            </el-col>
        </el-row>
        <hr />
    </div>
    <div v-if="list.length > 0">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            v-model:current-page="pageNo" v-model:page-size="pageSize" layout="sizes, prev, pager, next"
            :small="'small'" :total="totalCount" :page-size="pageSize" :pager-count="5"
            :page-sizes="[10, 20, 30, 50]" />
    </div>
    <div v-if="list.length == 0" style="display:flex;justify-content: center;">
        <span style="color: gray;font-size: 14px;">暂无内容</span>
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import api from '../../../api/api.js';
import { useRouter } from 'vue-router';
// import { mapState } from 'vuex';
const searchText = ref("");
const list = ref([]);
const router = useRouter();
onMounted(async () => {
    console.log(1);
    var apiResult = await api.GetNoteList(1, 50, "", "");
    apiResult.list.Data.forEach(m => {
        var tempObj={};
        tempObj.name=m.Name;
        tempObj.id=m.Id;
        list.value.push(tempObj);
    });
});
const goPath = () => {
    var path = "/NoteSave/0";
    router.push({ path: path });
};
const calculateSpanCount = (item) => {
    let spanCount = 24;
    if (item.icon) spanCount -= 4
    if (item.rname) spanCount -= 6
    return spanCount;
}
const goInfoHref=(id)=>{
    var path = "/NoteInfo/"+id;
    router.push({ path: path });
}
// export default {
//     data() {
//         return {
//             menuList:[],
//             entityName:"note",
//             infoHref: "",
//             listProp: {},

//             searchText
//         }
//     },
//     created() {
//         this.initEntity();
//     },
//     computed: {
//         ...mapState('user', ['douTable'])
//     },
//     methods: {
//         goPath() {
//             var layoutStr = this.$router.currentRoute._value.path.split('/')[1];
//             this.entityObj = this.douTable[this.entityName];
//             let path = "";
//             switch (this.entityName) {
//                 case "borrow": {
//                     path = "/Bill/BorrowSave/0";
//                 } break;
//                 default: {
//                     if (layoutStr == "pl") {
//                         path = "/ps/" + this.entityName + "/0";
//                     } else {
//                         path = "/" + layoutStr + "/ps/" + this.entityName + "/0";
//                     }
//                 } break;
//             }
//             this.$router.push({ path: path });
//         },
//         initEntity() {
//             this.entityObj = this.douTable[this.entityName];
//             var layoutStr = this.$router.currentRoute._value.path.split('/')[1];
//             var path=layoutStr;
//             this.infoHref = path;
//             this.listProp.name = "m.name";
//             this.listProp.memo1 = "m.content.substring(0, 50) + '...'";
//         }
//     }
// }
</script>
<style>
.el-form--inline .el-form-item {
    margin-right: 10px;
}
</style>