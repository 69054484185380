<template>
    <div class="d_main">
        <div class="d_title">{{ wheelInfo.name }}</div>
        <div class="d_description">{{ wheelInfo.description }}</div>
        <div>
            <LuckyWheel width="260px" height="260px" ref="wheel" :prizes="prizes" :buttons="buttons" @start="onStart"
                @end="onEnd" />
        </div>
    </div>
    <div style="margin: 5px 0;">
        <span class="d_span d_bd_blue">活动规则</span>
    </div>
    <div style="font-size: 12px;">
        <div>时间：{{ wheelInfo.time }}</div>
        <div>
            <span v-if="wheelInfo.drawCount != 0 || wheelInfo.dayDrawCount != 0">规则：</span>
            <span v-if="wheelInfo.drawCount != 0">活动期间一共可以抽取 {{ wheelInfo.drawCount }} 次，总剩余抽取 {{ wheelInfo.canDrawCount
                }} 次；</span>
            <span v-if="wheelInfo.dayDrawCount != 0">活动期间每日可以抽取 {{ wheelInfo.dayDrawCount }} 次，今日剩余抽取 {{
                wheelInfo.canDayDrawCount }} 次。</span>
        </div>
    </div>
    <div style="margin: 5px 0;">
        <span class="d_span d_bd_blue">中奖概率</span>
    </div>
    <div>
        <el-table :data="prizes" style="width: 100%" size="small">
            <el-table-column prop="name" label="名称" header-align="center" />
            <el-table-column width="120" prop="probability" label="概率(%)" align="center" />
            <el-table-column width="120" prop="quantity" label="剩余数量" align="center" />
        </el-table>
    </div>
    <div style="margin: 5px 0;">
        <span class="d_span d_bd_blue">抽奖结果</span>
    </div>
    <div>
        <el-table :data="resultList" style="width: 100%" size="small">
            <el-table-column prop="name" label="名称" header-align="center" />
            <el-table-column width="150" prop="createdOn" label="时间" align="center" />
            <el-table-column width="80" prop="isCash" align="center" label="是否兑奖" />
        </el-table>
    </div>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue';
import { LuckyWheel } from '@lucky-canvas/vue';
import api from '../../../api/api.js';
import { ElMessage } from 'element-plus';
import { ElMessageBox } from 'element-plus';
import dayjs from 'dayjs';

const prizes = ref([]);
const resultList = ref([]);
const buttons = ref([{
    radius: '20%',
    background: '#FFFFFF',
    // pointer: true,
    fonts: [{ text: '开始', top: '-10px' }]
}]);
const props = defineProps(["pid", "id"]);
const wheel = ref(null);
const wheelInfo = ref({});
const lastResult = ref({});

const fetchWheelData = async () => {
    try {
        const response = await api.GetWheelConfig(props.pid, props.id); // 获取配置
        prizes.value = response.prize.map((item) => ({
            name: item.name,
            background: item.color,
            fonts: [{ text: item.name, top: '30%' }],
            id: item.id,
            probability: item.probability,
            quantity: item.quantity
        }));
        let tempResult = [];
        response.result.forEach(temp => {
            let obj = temp;
            obj.createdOn = dayjs(temp.createdOn + "Z").format("YYYY-MM-DD HH:mm:ss");
            if (temp.isCash == true) {
                obj.isCash = "是";
            } else {
                obj.isCash = "否";
            }
            if (!temp.isPrize) {
                obj.name = "未中奖";
            }
            tempResult.push(obj);
        });
        resultList.value = tempResult;
        wheelInfo.value = response.info;
    } catch (error) {
        console.error('获取转盘配置失败', error);
    }
};

const startLottery = async () => {
    try {
        wheel.value.play(0);
        const response = await api.DrawLottery(props.pid, props.id, false); // 后台计算中奖结果
        const prizeIndex = response.selectedIndex;
        lastResult.value = response.last;
        // 模拟旋转时间，确保转盘旋转一段时间后再停止
        setTimeout(() => {
            // 传入中奖索引停止转盘
            wheel.value.stop(prizeIndex);
        }, 500);
    } catch (error) {
        ElMessage.error("抽奖失败" + error.response.data);
    }
};

const onStart = () => {
    // var message = "今日抽奖次数已用完，请明日再试。";
    // if (wheelInfo.value.canDayDrawCount == 0) {
    //     if (wheelInfo.value.canDrawCount == 0) {
    //         message="本次活动抽奖次数已用完。";
    //     }
    //     ElMessageBox.alert(message, '提示', {
    //         confirmButtonText: '确认',
    //         callback: () => {
    //         },
    //     })
    //     return;
    // }
    startLottery();
};
const onEnd = (prize) => {
    //计算减少数量
    var choosedPrize = prizes.value.find(m => m.id == prize.id);
    //添加结果
    let obj = lastResult.value;
    obj.createdOn = dayjs(obj.createdOn).format("YYYY-MM-DD HH:mm:ss");
    if (obj.isCash == true) {
        obj.isCash = "是";
    } else {
        obj.isCash = "否";
    }
    if (!obj.isPrize) {
        obj.name = "未中奖";
    }
    resultList.value.splice(0, 0, obj);
    wheelInfo.value.canDayDrawCount -= 1;
    wheelInfo.value.canDrawCount -= 1;
    if (choosedPrize.quantity != '不限量') {
        choosedPrize.quantity = choosedPrize.quantity - 1;
    }
    var messageTitle = "谢谢支持，祝您下次好运。";
    if (prize.name.indexOf('谢谢') == -1) {
        messageTitle = "恭喜您抽中了[" + prize.name + "]";
    }
    ElMessageBox.alert(messageTitle, '抽奖结果', {
        confirmButtonText: '确认',
        callback: () => {
        },
    })
};

onMounted(fetchWheelData);
</script>

<style scoped>
.d_main {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.d_title {
    font-weight: bold;
    font-size: 22px;
}

.d_description {
    margin: 5px 0;
    font-size: 15px;
}
</style>